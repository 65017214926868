import React, { useState, useRef, useLayoutEffect } from 'react';
import { Element } from 'react-scroll';
import { styled } from 'twin.macro';
import { Link } from 'react-scroll';

import { Box, Button } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/landing5/Hero';
import Works from '../sections/landing5/Works';
import CTA from '../sections/landing5/CTA';
import HeroVideo from '../sections/landing5/HeroVideo';
import { Container } from 'react-bootstrap';

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <video
            autoplay
            controls
            style={{ width: '100%', paddingBottom: '1rem' }}
          >
            <source
              src={
                'https://3rdaveevents.s3.us-west-1.amazonaws.com/hero-caps-trimmed.mp4'
              }
              type="video/mp4"
            />
          </video>
        </Container>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
