import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button, Title, Text, Box } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Contact from '../sections/common/Contact';
import imgWorkCover from '../assets/image/jpg/details/details-1.jpg';
import imgS1 from '../assets/image/jpg/details/details-6.jpg';
import imgS2 from '../assets/image/jpg/details/details-5.jpg';
import imgS3 from '../assets/image/jpg/details/details-4.jpg';
import imgS4 from '../assets/image/jpg/details/details-3.jpg';
import imgS5 from '../assets/image/jpg/details/details-8.jpg';
import imgS6 from '../assets/image/jpg/details/details-7.jpg';
import { device } from '../utils';
import { agencyWorks1, getProjectDetails } from '../data';

import axios from 'axios';

const WorkSingle = (props) => {
  console.log('props', props.location.search);
  const eventSlug = props.location.search.replace(/\?/g, '');
  const [details, setDetails] = useState([]);

  useEffect(() => {
    console.log('HERE');
    axios
      .get(
        `https://third-capabilities.herokuapp.com/api/projects/project/${eventSlug}`
      )
      .then((res) => {
        console.log(res.data);
        setDetails(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // getProjectDetails(eventSlug)
    //   .then((res) => {
    //     console.log(res.data);
    //     setDetails(res.data);
    //     // setProjectId(res.data._id);
    //     // setPrelimInfoSubmitted(true);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  // useEffect(() => {
  //   getProjectDetails()
  //     .then((res) => {
  //       console.log(res.data);
  //       setDetails(res.data);
  //       // setItems(res.data);
  //       // setProjectId(res.data._id);
  //       // setPrelimInfoSubmitted(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   // projectDetails(eventSlug)
  //   //   .then((res) => {
  //   //     console.log(res.data);
  //   //     // setDetails(res.data);
  //   //     // setProjectId(res.data._id);
  //   //     // setPrelimInfoSubmitted(true);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });
  //   // // agencyWorks1.forEach(function(value) {
  //   // //   if (value.slug === eventSlug) {
  //   // //     console.log(value);
  //   // //     setDetails(value);
  //   // //   }
  //   // // });
  // }, []);
  return (
    <>
      {details.categories && (
        <PageWrapper>
          <Container
            fluid
            className="px-sm-5"
            css={`
              margin-top: 92px;
            `}
          >
            <img src={details.featured} alt="" className="img-fluid w-100" />
          </Container>
          <div className="mt-lg-5">
            <Container>
              <Row>
                <Col lg="4" className="mb-4 mb-lg-0">
                  <Text variant="tag">Client</Text>
                  <Title variant="cardBig" className="mt-3">
                    {details.client}
                  </Title>
                </Col>
                <Col lg="4" className="mb-4 mb-lg-0">
                  <Text variant="tag">Time</Text>
                  <Title variant="cardBig" className="mt-3">
                    {details.date}
                  </Title>
                </Col>
                {details.workLink && (
                  <Col lg="4">
                    <Button arrowRight>Live work</Button>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
          <Container className="mt-lg-4">
            <Row>
              <Col lg="8">
                <Text variant="tag">{details.categories[0]}</Text>
                <Title variant="secSm" className="my-4">
                  {details.tagline}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                    white-space: pre-wrap;
                  `}
                >
                  {details.overview}
                </Text>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="8">
                <Title variant="secSm" className="my-4">
                  Touchpoints
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                    white-space: pre-wrap;
                  `}
                >
                  {details.touchpoints}
                </Text>
              </Col>
            </Row>
          </Container>
          {details.sizzle && (
            <Container>
              <Row>
                <Col xs="12" className="mb-5">
                  <video
                    controls
                    className="img-fluid w-100"
                    poster={details.keyImages[0]}
                  >
                    <source src={details.sizzle} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Col>
              </Row>
            </Container>
          )}
          <Container>
            <Row>
              {details.keyImages[0] && !details.sizzle && (
                <Col xs="12" className="mb-5">
                  <img
                    src={details.keyImages[0]}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              )}
              {details.keyImages[1] && (
                <Col xs="12" className="mb-5">
                  <img
                    src={details.keyImages[1]}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              )}
              {details.keyImages[2] && (
                <Col lg="6" className="mb-5 pr-lg-4">
                  <img
                    src={details.keyImages[2]}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              )}
              {details.keyImages[3] && (
                <Col lg="6" className="pl-lg-4 mb-5">
                  <img
                    src={details.keyImages[3]}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              )}
            </Row>
          </Container>
          {details.keyImages[4] && (
            <Container className="mt-lg-1">
              <Row>
                {details.keyImages[4] && (
                  <Col xs="12" className="mb-5">
                    <img
                      src={details.keyImages[4]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
                {details.keyImages[5] && (
                  <Col xs="12" className="mb-5">
                    <img
                      src={details.keyImages[5]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
                {details.keyImages[6] && (
                  <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                    <img
                      src={details.keyImages[6]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
                {details.keyImages[7] && (
                  <Col lg="6" className="pl-lg-4 mb-5">
                    <img
                      src={details.keyImages[7]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
              </Row>
            </Container>
          )}
          <Section mt="20%" bg="dark">
            <Container>
              <Row
                css={`
                  margin-top: -40%;
                `}
              >
                {details.keyImages[8] && (
                  <Col xs="12" className="mb-5">
                    <img
                      src={details.keyImages[8]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
                {details.keyImages[9] && (
                  <Col xs="12" className="mb-5">
                    <img
                      src={details.keyImages[9]}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </Col>
                )}
              </Row>

              {/* <div className="text-center mt-lg-5">
                <Text variant="tag" className="mb-1" color="lightShade">
                  Next Project
                </Text>
                <Link to="portfolio-details">
                  <Button
                    arrowRight
                    className="border-0 bg-transparent shadow-none text-capitalize py-3"
                    css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                  >
                    Replace
                  </Button>
                </Link>
              </div> */}
            </Container>
          </Section>
          {/* <Box py={4}>
            <Contact />
          </Box> */}
        </PageWrapper>
      )}
    </>
  );
};
export default WorkSingle;
