import React, { useState, useRef, useLayoutEffect } from 'react';
import { Element } from 'react-scroll';
import { styled } from 'twin.macro';
import { Link } from 'react-scroll';

import { Box, Button } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/landing5/Hero';
import Works from '../sections/landing5/Works';
import CTA from '../sections/landing5/CTA';
import HeroVideo from '../sections/landing5/HeroVideo';

export const Overlay = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 00%, black 100%);
  z-index: 1;
`;
export const HeroText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10%;
  transition: padding 0.5s ease;
  @media (min-width: 768px) {
    padding: 10% 7rem 10%;
  }
`;
export const HeroButton = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10%;
  transition: padding 0.5s ease;
  @media (min-width: 768px) {
    padding: 10% 7rem 10%;
  }
`;

const opacityFromScroll = (element) => {
  if (element) {
    const distanceFromDocumentTop = element.offsetTop + element.offsetHeight;
    const distanceFromViewport = element.getBoundingClientRect().bottom;
    const opacity = (distanceFromViewport / distanceFromDocumentTop).toFixed(1);
    return opacity > 0 && opacity <= 1 ? opacity : 1;
  }

  return 1;
};

const IndexPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const [titleOpacity, setTitleOpacity] = useState(1);
  const mainTitleRef = useRef();

  useLayoutEffect(() => {
    const handler = () => {
      if (scrolled && window.scrollY === 0) {
        setScrolled(false);
      } else if (!scrolled && window.scrollY > 0) {
        setScrolled(true);
      }
      const opacity = opacityFromScroll(mainTitleRef.current);
      setTitleOpacity(opacity);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [scrolled, mainTitleRef]);

  return (
    <>
      <PageWrapper darkTheme>
        <Box bg="info">
          <HeroVideo src={'/static/video.mp4'}>
            <Overlay />
            <HeroText>
              <img
                className="text-4xl md:text-5xl lg:text-6xl text-white font-bold text-center text-shadow-lg"
                style={{
                  zIndex: 1,
                  opacity: `${titleOpacity}`,
                  margin: '0 auto',
                  maxWidth: '175px',
                  width: '50%',
                }}
                ref={mainTitleRef}
                src={'/static/3rdAveLogo.png'}
              />
            </HeroText>
            <HeroButton>
              <div
                style={{
                  zIndex: 1,
                  opacity: `${titleOpacity}`,
                  margin: '0 auto',
                  maxWidth: '194px',
                  width: '50%',
                  top: '92%',
                  position: 'relative',
                }}
              >
                <Link
                  to="works"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button variant="light" style={{ color: 'black' }}>
                    Explore works
                  </Button>
                </Link>
              </div>
            </HeroButton>
          </HeroVideo>
          <Element name="works">
            <Works />
          </Element>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
