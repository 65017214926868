import React, { useState, useRef, useLayoutEffect } from 'react';
import { Element } from 'react-scroll';
import { styled } from 'twin.macro';
import { Link } from 'react-scroll';
import { Form } from "react-bootstrap";
import axios from 'axios';

import '../assets/styles.css'
import { Input, TextArea, Box, Button } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/landing5/Hero';
import Works from '../sections/landing5/Works';
// import CTA from '../sections/landing5/CTA';
import HeroVideo from '../sections/landing5/HeroVideo';
import logo from '../assets/image/png/logo.png';

const server = 'https://third-capabilities.herokuapp.com/api';

export const Overlay = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 00%, black 100%);
  z-index: 1;
`;
export const HeroText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10%;
  transition: padding 0.5s ease;
  @media (min-width: 768px) {
    padding: 10% 7rem 10%;
  }
`;
export const HeroButton = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10%;
  transition: padding 0.5s ease;
  @media (min-width: 768px) {
    padding: 10% 7rem 10%;
  }
`;

const opacityFromScroll = (element) => {
  if (element) {
    const distanceFromDocumentTop = element.offsetTop + element.offsetHeight;
    const distanceFromViewport = element.getBoundingClientRect().bottom;
    const opacity = (distanceFromViewport / distanceFromDocumentTop).toFixed(1);
    return opacity > 0 && opacity <= 1 ? opacity : 1;
  }

  return 1;
};

const IndexPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const [viewableProjects, setViewableProjects] = useState([]);


  const [titleOpacity, setTitleOpacity] = useState(1);
  const mainTitleRef = useRef();

  useLayoutEffect(() => {
    const handler = () => {
      if (scrolled && window.scrollY === 0) {
        setScrolled(false);
      } else if (!scrolled && window.scrollY > 0) {
        setScrolled(true);
      }
      const opacity = opacityFromScroll(mainTitleRef.current);
      setTitleOpacity(opacity);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [scrolled, mainTitleRef]);


  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const checkAuthentication = () => {
    if (validateEmail(email)) {
      setShowError(false);
      axios.post(server + `/emails/validate-email`, { email: email })
        .then((res) => {
          if (res.data[0] && res.data[0].viewableProjects.length > 0) {
            setViewableProjects(res.data[0].viewableProjects);
            setAuthenticated(true);
          } else {
            setError('Not authorized. Please contact info@3rdave.com for access.');
            setShowError(true);
          }
          // setItems(res.data);
          // setProjectId(res.data._id);
          // setPrelimInfoSubmitted(true);
        })
        .catch((err) => {
          console.log(err);
          setError('Internal Error. Please try again later.');
          setShowError(true);
        });
    } else {
      setError('Please enter a valid email');
      setShowError(true);
    }
  }
  const updateEmail = (_email) => {
    setEmail(_email);
  }
  return (
    <>
      <PageWrapper darkTheme>
        {!authenticated && (
          <div className="security-overlay">
            <div className="security-inner">
              {/* You still need to add the hidden input with the form name to your JSX form */}
              <input type="hidden" name="form-name" value="contact" />
              <div className="mt-4 mx-auto">
                <Input type="email" placeholder="Email address" required name="email" value={email} onChange={(e) => { updateEmail(e.target.value) }} />
              </div>
              {showError && (
                <div className="mt-1 mx-auto error-text">
                  <p>{error}</p>
                </div>
              )}
              <div className="mt-4 mt-lg-5">
                <Button arrowRight variant="primary" type="submit" onClick={() => { checkAuthentication() }}>
                  Enter Site
                </Button>
              </div>
            </div>
          </div>
        )}

        <Box bg="info">
          <HeroVideo
            src={
              'https://3rdaveevents.s3.us-west-1.amazonaws.com/hero-caps-trimmed.mp4'
            }
          >
            <Overlay />
            <HeroText>
              <img
                className="text-4xl md:text-5xl lg:text-6xl text-white font-bold text-center text-shadow-lg"
                style={{
                  zIndex: 1,
                  opacity: `${titleOpacity}`,
                  margin: '0 auto',
                  maxWidth: '175px',
                  width: '50%',
                }}
                ref={mainTitleRef}
                src={logo}
              />
            </HeroText>
            <HeroButton>
              <div
                style={{
                  zIndex: 1,
                  opacity: `${titleOpacity}`,
                  margin: '0 auto',
                  maxWidth: '194px',
                  width: '82%',
                  top: '96%',
                  position: 'relative',
                }}
              >
                <Link
                  to="works"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button variant="light" style={{ color: 'black' }}>
                    Recent work
                  </Button>
                </Link>
              </div>
            </HeroButton>
          </HeroVideo>

          {authenticated && (
            <Element name="works">
              <Works viewableProjects={viewableProjects} />
            </Element>
          )}

          {/* <CTA /> */}
        </Box>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
