export const menuItems = [
  // {
  //   name: "demos",
  //   label: "creative demos",
  //   items: [
  //     { name: "", label: "designer 01" },
  //     { name: "designer-2", label: "designer 02" },
  //     { name: "devloper-1", label: "devloper 01" },
  //     { name: "devloper-2", label: "devloper 02" },
  //     { name: "agency-1", label: "agency 01" },
  //     { name: "agency-2", label: "agency 02" },
  //     { name: "photography-1", label: "photography 01" },
  //     { name: "photography-2", label: "photography 02" },
  //   ],
  // },
  // {
  //   name: 'pages',
  //   label: 'pages',
  //   items: [
  //     {
  //       name: 'portfolio',
  //       label: 'portfolio',
  //       items: [
  //         {
  //           name: 'masonry',
  //           label: 'masonry style',
  //           items: [
  //             { name: 'masonry-2-column', label: 'masonry grid 2' },
  //             {
  //               name: 'masonry-3-column-fluid',
  //               label: 'masonry grid 3 fluid',
  //             },
  //             {
  //               name: 'masonry-3-column',
  //               label: 'masonry grid 3',
  //             },
  //             {
  //               name: 'masonry-4-column-fluid',
  //               label: 'masonry grid 4 fluid',
  //             },
  //             {
  //               name: 'masonry-4-column',
  //               label: 'masonry grid 4',
  //             },
  //           ],
  //         },
  //         {
  //           name: 'grid',
  //           label: 'grid style',
  //           items: [
  //             {
  //               name: 'grid-2',
  //               label: 'grid style 2',
  //             },
  //             {
  //               name: 'grid-3',
  //               label: 'grid style 3',
  //             },
  //             {
  //               name: 'grid-3-fluid',
  //               label: 'grid style 3 fluid',
  //             },
  //             {
  //               name: 'grid-4',
  //               label: 'grid style 4',
  //             },
  //             {
  //               name: 'grid-4-fluid',
  //               label: 'grid style 4 fluid',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'portfolio-details',
  //       label: 'portfolio Details',
  //       items: [
  //         { name: 'portfolio-details', label: 'details 01' },
  //         { name: 'portfolio-details-2', label: 'details 02' },
  //       ],
  //     },
  //     { name: 'about', label: 'about' },
  //     { name: 'contact', label: 'contact' },
  //   ],
  // },
];
