import imgT1 from '../assets/image/jpg/agency/regular/portfolio-9.jpg';
import imgT2 from '../assets/image/jpg/agency/regular/portfolio-8.jpg';
import imgT3 from '../assets/image/jpg/agency/regular/portfolio-7.jpg';
import imgT4 from '../assets/image/jpg/agency/regular/portfolio-6.jpg';
import imgT5 from '../assets/image/jpg/agency/regular/portfolio-5.jpg';
import imgT6 from '../assets/image/jpg/agency/regular/portfolio-4.jpg';
import imgT7 from '../assets/image/jpg/agency/regular/portfolio-3.jpg';
import imgT8 from '../assets/image/jpg/agency/regular/portfolio-2.jpg';
import imgT9 from '../assets/image/jpg/agency/regular/portfolio-1.jpg';

import projectOneThumbImage from '../assets/image/jpg/agency/project-one/featured/hbo-carwash-thumb.jpg';
import projectOneFeaturedImage from '../assets/image/jpg/agency/project-one/featured/hbo-carwash-featured.jpg';
import projectOneKeyA from '../assets/image/jpg/agency/project-one/key/one.jpg';
import projectOneKeyB from '../assets/image/jpg/agency/project-one/key/two.jpg';
import projectOneKeyC from '../assets/image/jpg/agency/project-one/key/three.jpg';
import projectOneKeyD from '../assets/image/jpg/agency/project-one/key/four.jpg';
import projectOneKeyE from '../assets/image/jpg/agency/project-one/key/five.jpg';
import projectOneKeyF from '../assets/image/jpg/agency/project-one/key/six.jpg';
import projectOneKeyG from '../assets/image/jpg/agency/project-one/key/seven.jpg';
import projectOneKeyH from '../assets/image/jpg/agency/project-one/key/eight.jpg';
import projectOneKeyI from '../assets/image/jpg/agency/project-one/key/nine.jpg';
import projectOneKeyJ from '../assets/image/jpg/agency/project-one/key/ten.jpg';
import projectOneKeyK from '../assets/image/jpg/agency/project-one/key/eleven.jpg';
import axios from 'axios';

const server = 'https://third-capabilities.herokuapp.com/api/projects';

export const agencyWorks1 = (data, id) => {
  return axios.get(server + `/projects`);
};

export const getProjectDetails = (eventSlug) => {
  return axios.get(server + `/projects/${eventSlug}`);
};

export const validateEmail = (email) => {
  return axios.post(server + `/emails/validate-email`, { email: email });
}

// export const agencyWorks1 = [
//   {
//     title: `The Lady and The Dale Car Wash`,
//     slug: `hbo-carwash`,
//     tagline: `An immersive branded drive-through experience`,
//     client: `HBOMAX`,
//     date: `February 2021`,
//     overview: `To celebrate the launch of The Lady and The Dale documentary, HBOMax hosted a drive through experience that immersed guests in to the setting of the film while they also received a free car wash.

// 3RD AVE designed and produced an immersive car wash experience. As guests entered the carwash line, they witnessed a 1950’s car chase scene, and while getting their car washed they drove by an office murder scene that is featured in the film.  Throughout the experience guests listened to a custom audio track being broadcast on an FM transmitter. Guests stayed in their vehicle the entire time to ensure a safe guest experience. Guests were encouraged to share their experience on a custom IG filter, designed and deployed by 3RD AVE.

// In the end, over 1,000 guests came to the carwash during the two-day activation. Guest signed a COVID waiver, designed and deployed by 3RD AVE. In the end, 914 IG filters were opened which lead to 6.5K total impressions on social.`,
//     touchpoints: `Renderings | Scenic Design & Propping | Spatial Planning & Layouts | Logistics | A/V | Staging and Equipment Rentals | Signage Design and Install | COVID Compliance & Safety Measures | COVID Screenings`,
//     keyImages: [
//       projectOneKeyA,
//       projectOneKeyB,
//       projectOneKeyC,
//       projectOneKeyD,
//       projectOneKeyE,
//       projectOneKeyF,
//       projectOneKeyG,
//       projectOneKeyH,
//       projectOneKeyI,
//       projectOneKeyJ,
//       projectOneKeyK,
//     ],
//     categories: [`Covid Conscious`],
//     featured: projectOneFeaturedImage,
//     thumbnail: projectOneThumbImage,
//     link: '/portfolio-details/?hbo-carwash',
//   },
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`app`, `web`],
//     thumbnail: imgT2,
//     link: '/portfolio-details/?hbo-carwash',
//   },
//   {
//     brand: `Shopper`,
//     slug: `shopper`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`web`, `ui-design`],
//     thumbnail: imgT3,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`app`, `ui-design`, `web`],
//     thumbnail: imgT4,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`app`, `ui-design`],
//     thumbnail: imgT5,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `App Ice Cream`,
//     slug: `app-ice-cream`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ui-design`, `web`],
//     thumbnail: imgT6,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `Camera`,
//     slug: `Camera`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`app`, `ui-design`],
//     thumbnail: imgT7,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `Smart Watch`,
//     slug: `smart-watch`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`web`, `ui-design`],
//     thumbnail: imgT8,
//     link: '/portfolio-details',
//   },
//   {
//     brand: `Shopper`,
//     slug: `shopper`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`web`, `ui-design`],
//     thumbnail: imgT9,
//     link: '/portfolio-details',
//   },
// ];
